import React from "react";
import dayjs from "dayjs";
// Adjust the import paths based on your project structure:
import { TooltipProvider, TooltipContent, TooltipTrigger, Tooltip } from "@/components/ui/tooltip";
interface DateTooltipProps {
  /** The date to display in the tooltip */
  date?: Date | string | number | null;
  /** Child element(s) to wrap; typically a span or inline element */
  children: React.ReactNode;
}
export const DateTooltip: React.FC<DateTooltipProps> = ({
  date,
  children
}) => {
  // Format date as: February 13, 2024 at 3:45 PM

  if (!date) return null;
  const formattedDate = dayjs(date).format("MMMM D, YYYY [at] h:mm A");
  return <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-component="DateTooltip" data-sentry-source-file="DateTooltip.tsx">
      <Tooltip delayDuration={50} data-sentry-element="Tooltip" data-sentry-source-file="DateTooltip.tsx">
        <TooltipTrigger asChild className="cursor-default" suppressHydrationWarning data-sentry-element="TooltipTrigger" data-sentry-source-file="DateTooltip.tsx">
          {children}
        </TooltipTrigger>
        <TooltipContent side="top" align="center" suppressHydrationWarning data-sentry-element="TooltipContent" data-sentry-source-file="DateTooltip.tsx">
          {formattedDate}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>;
};