// MessageRenderers.tsx

import { Notification } from "@/graphql/generated/types";
import { cn } from "@/lib/utils";
import Link from "next/link";

// Subcomponent props
interface BaseMessageProps {
  actorNames: string[];
  metadata: Notification["metadata"] | null;
  entityPreview?: string | null;
  variant?: "default" | "navbar";
  actorsCount: number;
  maybeRenderOthers: () => JSX.Element | null;
}

// 1) FOLLOW_USER
export function FollowUserMessage({
  actorNames,
  variant,
  maybeRenderOthers,
  actorsCount
}: BaseMessageProps) {
  const twoActors = actorNames.slice(0, 2).join(", ");
  if (actorNames.length <= 2 || actorNames.length === actorsCount) {
    return <p className={cn("text-xs leading-none text-white font-semibold ", variant !== "navbar" && "md:text-sm md:leading-none")}>
        {actorNames.join(", ")}{" "}
        <span className={cn("text-xs leading-none font-normal text-[#e6e6fa]/90", variant !== "navbar" && "md:text-sm md:leading-none")}>
          started <span className="leading-none">following</span> you
        </span>
      </p>;
  }
  // more than 2
  return <p className={cn("text-xs leading-none text-white font-semibold", variant !== "navbar" && "md:text-sm md:leading-none")} data-sentry-component="FollowUserMessage" data-sentry-source-file="NotificationMessageRenderers.tsx">
      {twoActors} {maybeRenderOthers()}{" "}
      <span className={cn("text-xs leading-none font-normal text-[#e6e6fa]/90", variant !== "navbar" && "md:text-sm md:leading-none")}>
        started{" "}
        <span className="text-white leading-none font-semibold">following</span>{" "}
        you
      </span>
    </p>;
}

// 2) LIKE_REVIEW
export function LikeReviewMessage({
  actorNames,
  variant,
  maybeRenderOthers,
  metadata,
  actorsCount
}: BaseMessageProps) {
  const twoActors = actorNames.slice(0, 2).join(", ");
  // if (actorNames.length === actorsCount) {
  //   return (
  //     <p className={cn("text-xs text-white font-semibold ")}>
  //       {actorNames.join(", ")}{" "}
  //       <span className="text-xs leading-none font-normal text-[#e6e6fa]/90">
  //         started <span className="text-white font-semibold">following</span>{" "}
  //         you
  //       </span>
  //     </p>
  //   )
  // }
  if (actorNames.length <= 2 || actorNames.length === actorsCount) {
    return <div className="flex flex-col gap-2">
        <p className={cn("text-xs line-clamp-1 leading-none font-bold text-white", variant !== "navbar" && "md:text-sm md:font-semibold")}>
          {actorNames.join(", ")}{" "}
          <span className={cn("text-xs leading-none max-md:hidden font-normal text-[#e6e6fa]/90", variant === "navbar" && "hidden", variant !== "navbar" && "md:text-sm md:leading-none")}>
            Liked your review on{" "}
            <Link href={`/books/${metadata?.bookSlug}`} className={cn("text-xs leading-none md:text-sm font-semibold text-white")}>
              {metadata?.bookTitle || "this book"}
            </Link>
          </span>
        </p>

        <span className={cn("text-xs leading-none line-clamp-1 font-normal h-fit text-[#e6e6fa]/90", variant !== "navbar" && "md:hidden")}>
          Liked your review on{" "}
          <Link href={`/books/${metadata?.bookSlug}`} className={cn("text-xs leading-none font-semibold text-white")}>
            {metadata?.bookTitle || "this book"}
          </Link>
        </span>
      </div>;
  }
  return <div className="flex flex-col gap-2" data-sentry-component="LikeReviewMessage" data-sentry-source-file="NotificationMessageRenderers.tsx">
      <p className={cn("text-xs line-clamp-1 leading-none font-bold text-white", variant !== "navbar" && "md:text-sm md:font-semibold")}>
        {twoActors} {maybeRenderOthers()}{" "}
        <span className={cn("text-sm leading-none font-normal max-md:hidden text-[#e6e6fa]/90", variant === "navbar" && "hidden")}>
          Liked your review on{" "}
          <Link href={`/books/${metadata?.bookSlug}`} className={cn("text-sm leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
            {metadata?.bookTitle || "this book"}
          </Link>
        </span>
      </p>

      <p className={cn("text-xs line-clamp-1 leading-none font-normal h-fit text-[#e6e6fa]/90", variant !== "navbar" && "md:hidden")}>
        Liked your review on{" "}
        <Link href={`/books/${metadata?.bookSlug}`} className={cn("text-xs leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
          {metadata?.bookTitle || "this book"}
        </Link>
      </p>
    </div>;
}

// 3) LIKE_COMMENT
export function LikeCommentMessage({
  actorNames,
  variant,
  maybeRenderOthers,
  metadata,
  actorsCount
}: BaseMessageProps) {
  const twoActors = actorNames.slice(0, 2).join(", ");
  if (actorNames.length <= 2 || actorsCount === actorNames.length) {
    return <div className="flex flex-col gap-2">
        <p className={cn("text-xs line-clamp-1 leading-none font-bold text-white", variant !== "navbar" && "md:text-sm md:font-semibold")}>
          {actorNames.join(", ")}{" "}
          <span className={cn("text-sm leading-none font-normal max-md:hidden text-[#e6e6fa]/90", variant === "navbar" && "hidden")}>
            Liked your {metadata?.bookSlug ? "review" : ""} comment on{" "}
            <Link href={metadata?.bookSlug ? `/books/${metadata?.bookSlug}` : `/lists/${metadata?.listSlug}`} className={cn("text-xs md:text-sm leading-none font-semibold text-white")}>
              {metadata?.bookTitle || metadata?.listName || "this book"}
            </Link>
          </span>
        </p>

        <p className={cn("text-xs leading-none font-normal line-clamp-1 h-fit text-[#e6e6fa]/90", variant !== "navbar" && "md:hidden")}>
          Liked your review comment on{" "}
          <Link href={metadata?.bookSlug ? `/books/${metadata?.bookSlug}` : `/lists/${metadata?.listSlug}`} className={cn("text-xs leading-none font-semibold text-white")}>
            {metadata?.bookTitle || metadata?.listName || "this book"}
          </Link>
        </p>
      </div>;
  }
  return <div className="flex flex-col gap-2" data-sentry-component="LikeCommentMessage" data-sentry-source-file="NotificationMessageRenderers.tsx">
      <p className={cn("text-xs line-clamp-1 leading-none font-bold text-white", variant !== "navbar" && "md:text-sm md:leading-none")}>
        {twoActors} {maybeRenderOthers()}{" "}
        <span className={cn("text-sm leading-none font-normal max-md:hidden text-[#e6e6fa]/90", variant === "navbar" && "hidden")}>
          Liked your review comment on{" "}
          <Link href={metadata?.bookSlug ? `/books/${metadata?.bookSlug}` : `/lists/${metadata?.listSlug}`} className={cn("text-xs md:text-sm leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
            {metadata?.bookTitle || metadata?.listName || "this book"}
          </Link>
        </span>
      </p>

      <p className={cn("text-xs leading-none line-clamp-1 font-normal h-fit text-[#e6e6fa]/90", variant !== "navbar" && "hidden")}>
        Liked your review comment on{" "}
        <Link href={metadata?.bookSlug ? `/books/${metadata?.bookSlug}` : `/lists/${metadata?.listSlug}`} className={cn("text-xs leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
          {metadata?.bookTitle || metadata?.listName || "this book"}
        </Link>
      </p>
    </div>;
}

// 4) REPLY_COMMENT
export function ReplyCommentMessage({
  actorNames,
  variant,
  metadata
}:
// maybeRenderOthers,
BaseMessageProps) {
  return <div className="flex flex-col gap-2" data-sentry-component="ReplyCommentMessage" data-sentry-source-file="NotificationMessageRenderers.tsx">
      <p className={cn("text-xs line-clamp-1 leading-none font-bold text-white", variant !== "navbar" && "md:text-sm md:leading-none")}>
        {actorNames[0]}{" "}
        <span className={cn("text-sm max-md:hidden leading-none font-normal text-[#e6e6fa]/90", variant === "navbar" && "hidden")}>
          Replied to your comment on{" "}
          <Link href={metadata?.bookSlug ? `/books/${metadata?.bookSlug}` : `/lists/${metadata?.listSlug}`} className={cn("text-sm leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
            {metadata?.bookTitle || metadata?.listName || "this book"}
          </Link>
        </span>
      </p>

      <p className={cn("text-xs leading-none line-clamp-1 font-normal h-fit text-[#e6e6fa]/90", variant !== "navbar" && "md:hidden")}>
        Replied to your comment on{" "}
        <Link href={metadata?.bookSlug ? `/books/${metadata?.bookSlug}` : `/lists/${metadata?.listSlug}`} className={cn("text-xs leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
          {metadata?.bookTitle || metadata?.listName || "this book"}
        </Link>
      </p>
    </div>;
  // }

  // else if (actorNames.length <= 2) {
  //   return (
  //     <p
  //       className={cn(
  //         "text-base font-semibold opacity-70",
  //         variant === "navbar" && "text-sm"
  //       )}
  //     >
  //       {actorNames.join(", ")}{" "}
  //       <span
  //         className={cn(
  //           "text-sm font-normal text-[#e6e6fa]/90",
  //           variant === "navbar" && "text-[11px] leading-4"
  //         )}
  //       >
  //         replied to your comment
  //       </span>
  //     </p>
  //   )
  // }
  // return (
  //   <p
  //     className={cn(
  //       "text-base font-semibold opacity-70",
  //       variant === "navbar" && "text-sm"
  //     )}
  //   >
  //     {twoActors} {maybeRenderOthers()}{" "}
  //     <span
  //       className={cn(
  //         "text-sm font-normal text-[#e6e6fa]/90",
  //         variant === "navbar" && "text-[11px] leading-4"
  //       )}
  //     >
  //       replied to your comment
  //     </span>
  //   </p>
  // )
}

// 5) NEW_COMMENT_REVIEW
export function NewCommentReviewMessage({
  actorNames,
  variant,
  // maybeRenderOthers,
  // previewText,
  metadata
}: BaseMessageProps) {
  return <div className="flex flex-col gap-2" data-sentry-component="NewCommentReviewMessage" data-sentry-source-file="NotificationMessageRenderers.tsx">
      <p className={cn("text-xs line-clamp-1 leading-none font-bold text-white", variant !== "navbar" && "md:text-sm md:font-semibold")}>
        {actorNames[0]}{" "}
        <span className={cn("text-sm leading-none font-normal max-md:hidden text-[#e6e6fa]/90", variant === "navbar" && "hidden")}>
          Commented on your review on{" "}
          <Link href={`/books/${metadata?.bookSlug}`} className={cn("text-sm leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
            {metadata?.bookTitle || "this book"}
          </Link>
        </span>
      </p>

      <p className={cn("text-xs leading-none font-normal line-clamp-1 h-fit text-[#e6e6fa]/90", variant !== "navbar" && "md:hidden")}>
        Commented on your review on{" "}
        <Link href={`/books/${metadata?.bookSlug}`} className={cn("text-xs leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
          {metadata?.bookTitle || "this book"}
        </Link>
      </p>
    </div>;
}
export function LikeListMessage({
  actorNames,
  variant,
  maybeRenderOthers,
  metadata,
  actorsCount
}: BaseMessageProps) {
  const twoActors = actorNames.slice(0, 2).join(", ");
  // if (actorNames.length === actorsCount) {
  //   return (
  //     <p className={cn("text-xs text-white font-semibold ")}>
  //       {actorNames.join(", ")}{" "}
  //       <span className="text-xs leading-none font-normal text-[#e6e6fa]/90">
  //         started <span className="text-white font-semibold">following</span>{" "}
  //         you
  //       </span>
  //     </p>
  //   )
  // }
  if (actorNames.length <= 2 || actorNames.length === actorsCount) {
    return <div className="flex flex-col gap-2">
        <p className={cn("text-xs line-clamp-1 leading-none font-bold text-white", variant !== "navbar" && "md:text-sm md:font-semibold")}>
          {actorNames.join(", ")}{" "}
          <span className={cn("text-xs leading-none max-md:hidden font-normal text-[#e6e6fa]/90", variant === "navbar" && "hidden", variant !== "navbar" && "md:text-sm md:leading-none")}>
            Liked your list,{" "}
            <Link href={`/lists/${metadata?.listSlug}`} className={cn("text-xs leading-none md:text-sm font-semibold text-white")}>
              {metadata?.listName || "this list"}
            </Link>
          </span>
        </p>

        <span className={cn("text-xs leading-none line-clamp-1 font-normal h-fit text-[#e6e6fa]/90", variant !== "navbar" && "md:hidden")}>
          Liked your list,{" "}
          <Link href={`/lists/${metadata?.listSlug}`} className={cn("text-xs leading-none font-semibold text-white")}>
            {metadata?.listName || "this list"}
          </Link>
        </span>
      </div>;
  }
  return <div className="flex flex-col gap-2" data-sentry-component="LikeListMessage" data-sentry-source-file="NotificationMessageRenderers.tsx">
      <p className={cn("text-xs line-clamp-1 leading-none font-bold text-white", variant !== "navbar" && "md:text-sm md:font-semibold")}>
        {twoActors} {maybeRenderOthers()}{" "}
        <span className={cn("text-sm leading-none font-normal max-md:hidden text-[#e6e6fa]/90", variant === "navbar" && "hidden")}>
          Liked your list,{" "}
          <Link href={`/lists/${metadata?.listSlug}`} className={cn("text-sm leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
            {metadata?.listSlug || "this slug"}
          </Link>
        </span>
      </p>

      <p className={cn("text-xs line-clamp-1 leading-none font-normal h-fit text-[#e6e6fa]/90", variant !== "navbar" && "md:hidden")}>
        Liked your list,{" "}
        <Link href={`/lists/${metadata?.listSlug}`} className={cn("text-xs leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
          {metadata?.listSlug || "this list"}
        </Link>
      </p>
    </div>;
}
export function NewCommentListMessage({
  actorNames,
  variant,
  // maybeRenderOthers,
  // previewText,
  metadata
}: BaseMessageProps) {
  return <div className="flex flex-col gap-2" data-sentry-component="NewCommentListMessage" data-sentry-source-file="NotificationMessageRenderers.tsx">
      <p className={cn("text-xs line-clamp-1 leading-none font-bold text-white", variant !== "navbar" && "md:text-sm md:font-semibold")}>
        {actorNames[0]}{" "}
        <span className={cn("text-sm leading-none font-normal max-md:hidden text-[#e6e6fa]/90", variant === "navbar" && "hidden")}>
          Commented on your list,{" "}
          <Link href={`/lists/${metadata?.listSlug}`} className={cn("text-sm leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
            {metadata?.listName || "this list"}
          </Link>
        </span>
      </p>

      <p className={cn("text-xs leading-none font-normal line-clamp-1 h-fit text-[#e6e6fa]/90", variant !== "navbar" && "md:hidden")}>
        Commented on your list,{" "}
        <Link href={`/lists/${metadata?.listSlug}`} className={cn("text-xs leading-none font-semibold text-white")} data-sentry-element="Link" data-sentry-source-file="NotificationMessageRenderers.tsx">
          {metadata?.listName || "this list"}
        </Link>
      </p>
    </div>;
}

// }

// else if (actorNames.length <= 2) {
//   return (
//     <p
//       className={cn(
//         "text-base font-semibold opacity-70",
//         variant === "navbar" && "text-sm"
//       )}
//     >
//       {actorNames.join(", ")}{" "}
//       <span
//         className={cn(
//           "text-sm font-normal text-[#e6e6fa]/90",
//           variant === "navbar" && "text-[11px] leading-4"
//         )}
//       >
//         commented on your review of{" "}
//         <span className="font-medium text-white">{bookTitle}</span>
//       </span>
//     </p>
//   )
// }
// return (
//   <p
//     className={cn(
//       "text-base font-semibold opacity-70",
//       variant === "navbar" && "text-sm"
//     )}
//   >
//     {twoActors} {maybeRenderOthers()}{" "}
//     <span
//       className={cn(
//         "text-sm font-normal text-[#e6e6fa]/90",
//         variant === "navbar" && "text-[11px] leading-4"
//       )}
//     >
//       commented on your review of{" "}
//       <span className="font-medium text-white">{bookTitle}</span>
//     </span>
//   </p>
// )

// // 6) SYSTEM_NOTIFICATION
// export function SystemNotificationMessage({
//   variant,
//   previewText,
// }: BaseMessageProps) {
//   return (
//     <div
//       className={cn(
//         "flex flex-col gap-2.5",
//         variant === "navbar" && "gap-[5px]"
//       )}
//     >
//       <span
//         className={cn(
//           "text-base font-semibold opacity-70",
//           variant === "navbar" && "text-sm"
//         )}
//       >
//         System
//       </span>
//       <span
//         className={cn(
//           "text-sm font-normal text-[#e6e6fa]/90",
//           variant === "navbar" && "text-xs"
//         )}
//       >
//         {previewText || "System notification"}
//       </span>
//     </div>
//   )
// }