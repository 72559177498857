/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
import dayjs from "dayjs"

declare module "dayjs" {
  interface Dayjs {
    calendarCustom(): string
  }
}

export const calendarCustom = (
  options: unknown,
  Dayjs: { prototype: any },
  dayjsFactory: typeof dayjs
) => {
  Dayjs.prototype.calendarCustom = function () {
    const now = dayjsFactory()
    const then = this
    const diffInSeconds = now.diff(then, "second")

    // Same day: ≤ 10 seconds → "Now"
    if (now.isSame(then, "day")) {
      if (diffInSeconds <= 10) {
        return "Now"
      }
      // Same day: < 1 hour → minutes (e.g., "15m")
      if (diffInSeconds < 3600) {
        let minutes = now.diff(then, "minute")
        if (minutes < 1) minutes = 1 // Ensure we show at least "1m"
        return `${minutes}m`
      }
      // Same day: ≥ 1 hour → hours (e.g., "5h")
      const hours = now.diff(then, "hour")
      return `${hours}h`
    }

    // Use startOf('day') to calculate calendar day differences
    const diffInDays = now.startOf("day").diff(then.startOf("day"), "day")

    // Previous calendar day → "Yesterday"
    if (now.startOf("day").subtract(1, "day").isSame(then.startOf("day"))) {
      return "Yesterday"
    }

    // 2-6 days ago → short day-of-week (e.g., "Tue")
    if (diffInDays >= 2 && diffInDays <= 6) {
      return then.format("ddd")
    }

    // Same year (after 1 week) → "Feb 13"
    if (now.year() === then.year()) {
      return then.format("MMM D")
    }

    // Previous year → "Feb 13, 2023"
    return then.format("MMM D, YYYY")
  }
}

export const isNotPublised = (date: string) => {
  return dayjs(date).isAfter(dayjs(), "day")
}
